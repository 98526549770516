import { useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemIcon,
  ListItemButton,
  CircularProgress,
  Skeleton,
  Stack,
} from '@mui/material';
// utils
import { fToNow } from 'utils/formatTime';
// _mock_
import { _notifications } from '_mock';
// components
import Iconify from 'components/Iconify';
import Scrollbar from 'components/Scrollbar';
import MenuPopover from 'components/MenuPopover';
import { IconButtonAnimate } from 'components/animate';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { ButtonPrimary } from 'components';
import { ReactSVG } from 'react-svg';
import { useNotification } from 'contexts/NotificationContext';
import Notification from './Notification';
import moment from 'moment';
import { TableNoData } from 'components/table';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { ordersNotifications, estimatesNotifications } = useNotification();
  const [open, setOpen] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const history = useHistory();
  const order = ordersNotifications.map((item) => item.order);
  const estimate = estimatesNotifications.map((item) => item.estimate);
  const notification = [...order, ...estimate];
  const notificationDesc = notification.sort((a: any, b: any) =>
    new Date(a.createdAt) <= new Date(b.createdAt) ? 1 : -1,
  );
  const today = moment(new Date()).format('ll');
  const toDayNOtification = notificationDesc.filter(
    (notification) =>
      moment(notification?.createdAt ?? new Date()).format('ll') === today,
  );
  console.log('la date ', toDayNOtification);
  // const sortOrder = ordersNotifications.reverse().sort();
  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const isNotFound = toDayNOtification.length === 0;

  const handleMarkAllAsRead = () => {
    // dispatch(markNotificationAsReadEffect(undefined, undefined, enqueueSnackbar, () => { }))
  };

  const handleDeleteAll = () => {
    // dispatch(deleteNotificationEffect(undefined, setDeleting, enqueueSnackbar))
  };
  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        {toDayNOtification.length > 0 ? (
          <Badge badgeContent={2} color="primary">
            <Iconify icon="eva:bell-fill" width={30} height={30} />
          </Badge>
        ) : (
          <Iconify icon="eva:bell-fill" width={30} height={30} />
        )}
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 2, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">
              {t('menu_item_notifications')}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('total_unread_notification').replace(
                '$total',
                String(toDayNOtification.length),
              )}
            </Typography>
          </Box>

          {2 > 0 && (
            <Tooltip title={t('mark_all_as_read')}>
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340 /* sm: 'auto', */ } }}>
          {/* {!loading && (newData ?? []).length > 0 && <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                {t('menu_item_new_notifications')}
              </ListSubheader>
            }
          >
            {newData.map((notification) => (
              <NotificationItem key={notification.id} notification={notification} handleClose={handleClose} />
            ))}

          </List>} */}

          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: 'overline' }}
              >
                {t('menu_item_old_notifications')}
              </ListSubheader>
            }
          >
            {/* {!loading && notifications.map((notification) => (
              <NotificationItem key={notification.id} notification={notification} handleClose={handleClose} />
            ))} */}

            <Stack rowGap={2} flexDirection={'column'}>
              {toDayNOtification.map((item, i) => {
                return <Notification notification={item} key={i} />;
              })}
            </Stack>
            <TableNoData
              // t={t}
              isNotFound={isNotFound}
              message={'empty_data_notifications'}
            />
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <ButtonPrimary
            fullWidth
            disableRipple
            onClick={() => {
              history.push('/app/notifications');
              handleClose();
            }}
            disabled={false}
          >
            {toDayNOtification.length >= 2
              ? t('menu_item_notifications_show_all')
              : 'Afficher'}
          </ButtonPrimary>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

interface NotificationItemProps {
  notification: any;
  handleClose: Function;
}

function NotificationItem({
  notification,
  handleClose,
}: NotificationItemProps) {
  const { t, i18n } = useTranslation();
  const { avatar, title } = renderContent(notification);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleMarkAsRead = () => {
    // dispatch(markNotificationAsReadEffect(notification.id, setLoading, enqueueSnackbar, () => { }));
    // switch (notification.type) {
    //   case 'NEW_ORDER':
    //     history.push(`/app/orders/${notification?.payload_json?.item_value}`);
    //     break;
    //   case 'PRODUCT_THRESHOLD':
    //     history.push(`/app/product/detail/${notification?.payload_json?.item_value}`);
    //     break;

    //   default:
    //     break;
    // }
    handleClose();
  };

  const handleDelete = () => {
    // dispatch(deleteNotificationEffect(notification.id, setLoading, enqueueSnackbar))
  };

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.read && {
          bgcolor: 'action.selected',
        }),
        '& .notification_actions': {
          display: 'none',
          position: 'absolute',
          right: 5,
        },
        ':hover .notification_actions': {
          display: 'block',
        },
      }}
      onClick={() => handleMarkAsRead()}
    >
      <ListItemAvatar>
        {loading ? (
          <>
            <CircularProgress />
          </>
        ) : (
          <Avatar
            sx={{
              bgcolor:
                notification.type === 'PRODUCT_THRESHOLD'
                  ? 'warning.lighter'
                  : notification.type === 'NEW_ORDER'
                  ? 'success.lighter'
                  : 'background.neutral',
              color:
                notification.type === 'PRODUCT_THRESHOLD'
                  ? 'warning.main'
                  : notification.type === 'NEW_ORDER'
                  ? 'success.main'
                  : 'text.primary',
            }}
          >
            {avatar}
          </Avatar>
        )}
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify
              icon="eva:clock-outline"
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {fToNow(notification.date_added, i18n.language)}
          </Typography>
        }
      />
      <ListItemIcon className="notification_actions">
        <Tooltip title={t('global_action_delete')}>
          <IconButton
            color="error"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleDelete();
            }}
          >
            <Iconify icon={'eva:trash-2-outline'} />
          </IconButton>
        </Tooltip>
      </ListItemIcon>
    </ListItemButton>
  );
}

function NotificationItemSkeleton() {
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
      }}
    >
      <ListItemAvatar>
        <Skeleton variant="circular" width={30} height={30} sx={{ mb: 1 }} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Skeleton
              variant="rectangular"
              width={250}
              height={15}
              sx={{ mb: 1 }}
            />
            <Skeleton
              variant="rectangular"
              width={250}
              height={10}
              sx={{ mb: 1 }}
            />
          </>
        }
        secondary={
          <Skeleton
            variant="rectangular"
            width={100}
            height={10}
            sx={{ mb: 1 }}
          />
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: any) {
  const { t, i18n } = useTranslation();
  const fr = i18n.language === 'fr';
  const title = (
    <>
      <Typography variant="subtitle2">
        {fr
          ? notification.payload_json.title.fr
          : notification.payload_json.title.en}
      </Typography>
      <Typography
        component="span"
        variant="body2"
        sx={{ color: 'text.secondary' }}
      >
        {fr
          ? notification.payload_json.body.fr ?? ''
          : notification.payload_json.body.en ?? ''}
      </Typography>
    </>
  );

  if (notification.type === 'PRODUCT_THRESHOLD') {
    return {
      avatar: (
        <ReactSVG
          alt={
            fr
              ? notification.payload_json.title.fr
              : notification.payload_json.title.en
          }
          src="/static/icons/navbar/ic_product.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'NEW_ORDER') {
    return {
      avatar: (
        <ReactSVG
          alt={
            fr
              ? notification.payload_json.title.fr
              : notification.payload_json.title.en
          }
          src="/static/icons/navbar/ic_cart.svg"
        />
      ),
      title,
    };
  }
  // if (notification.type === 'mail') {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_mail.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  // if (notification.type === 'chat_message') {
  //   return {
  //     avatar: (
  //       <img
  //         alt={notification.title}
  //         src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_chat.svg"
  //       />
  //     ),
  //     title,
  //   };
  // }
  return {
    // avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    avatar: (
      <img
        alt={
          fr
            ? notification.payload_json.title.fr
            : notification.payload_json.title.en
        }
        src="/bell.svg"
      />
    ),
    title,
  };
}
