import React, { useEffect, useState } from 'react';
import { SplashScreen } from 'components';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from "@mui/styles";
import SecureLS from 'secure-ls';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import config from 'config';


const ls = new SecureLS({ encodingType: 'aes' });
let token: undefined | string, user;

try {
  token = !!ls.get('token') ? ls.get('token') : undefined;
} catch (error) {
}

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      a: {
        textDecoration: 'none!important'
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%',
        //TODO Find best solution to fix it
        // "&> iframe": {
        //   display: 'none'
        // }
      },
      '#root': {
        height: '100%',
        width: '100%'
      }
    }
  })
);

function Auth(props: any) {
  const { children } = props;
  const [isLoading, setLoading] = useState(false);
  useStyles();
  useEffect(() => {
    if (token) {
    //   dispatch(list_mediatheque_effect({
    //     page: 1,
    //     per_page: 50
    //   }, () => {
    //   }, () => {
    //   }));
    //   dispatch(getAllNOtificationsEffect());
    //   dispatch(countUnreadNotificationEffect());
    }
    // dispatch(setTranslationFunction(t));
    // dispatch(loadGlobalDataEffect());
  }, [token]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

export default Auth;
