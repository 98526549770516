import PropTypes from 'prop-types';
import {AnimatePresence, m} from 'framer-motion';
// @mui
import {alpha} from '@mui/material/styles';
import {Box, IconButton, List, ListItem} from '@mui/material';
// utils
//
import Image from '../Image';
import Iconify from '../Iconify';
import {varFade} from '../animate';
import {FORMAT_IMG, FORMAT_VIDEO, getFileType} from 'utils/getFileFormat';
import FileThumbnail from "../file-thumbnail";
import React from "react";

// ----------------------------------------------------------------------

MultiFilePreview.propTypes = {
    urls: PropTypes.array.isRequired,
    onRemove: PropTypes.func,
    showPreview: PropTypes.bool,
};

export default function MultiFilePreview({showPreview = false, urls, onRemove}) {
    const hasFile = urls.length > 0;

    return (
        <List disablePadding sx={{...(hasFile && {my: 3})}}>
            <AnimatePresence>
                {urls.map((url, index) => {
                    if (showPreview) {
                        return (
                            <ListItem
                                key={url?.url ?? ""}
                                component={m.div}
                                {...varFade().inRight}
                                sx={{
                                    p: 0,
                                    m: 0.5,
                                    width: 80,
                                    height: 80,
                                    borderRadius: 1.25,
                                    overflow: 'hidden',
                                    position: 'relative',
                                    display: 'inline-flex',
                                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                                    "& img": {
                                        objectFit: "contain"
                                    }
                                }}
                            >
                                {
                                    FORMAT_IMG.includes(getFileType(url?.url ?? ""))
                                        ?
                                        <Image alt="preview" src={url?.url} ratio="1/1"/>
                                        : FORMAT_VIDEO.includes(getFileType(url?.url ?? "")) ? (
                                            <Box
                                                component="span"
                                                sx={{
                                                    width: 1,
                                                    lineHeight: 0,
                                                    display: 'block',
                                                    overflow: 'hidden',
                                                    position: 'relative',
                                                    paddingTop: '100%',
                                                    '& .wrapper': {
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        lineHeight: 0,
                                                        position: 'absolute',
                                                        backgroundSize: 'cover !important',
                                                    },
                                                }}
                                            >
                                                <CardMedia component={"video"} src={url}/>
                                            </Box>
                                        ) : (
                                            <FileThumbnail file={url} sx={{width: 40, height: 40}}/>
                                        )
                                }
                                {FORMAT_VIDEO.includes(getFileType(url?.url ?? "")) && <Box
                                    sx={{
                                        position: "absolute",
                                        background: "transparent",
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        margin: "auto",
                                        cursor: 'pointer',
                                        pt: 0.75,
                                        backdropFilter: 'blur(2px)',
                                        color: (theme) => theme.palette.text.primary
                                    }}
                                >
                                    <Iconify icon="fluent:play-32-regular" /*  icon="fa-solid:play" */
                                             width={30}
                                             height={30} sx={{':hover svg': {width: 40, height: 40}}}
                                    />
                                </Box>}

                                {onRemove && (
                                    <IconButton
                                        size="small"
                                        onClick={() => onRemove(url?.url)}
                                        sx={{
                                            top: 6,
                                            p: '2px',
                                            right: 6,
                                            position: 'absolute',
                                            color: 'common.white',
                                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                                            '&:hover': {
                                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                                            },

                                        }}
                                    >
                                        <Iconify
                                            icon={'mingcute:close-circle-fill'}
                                            width={14}
                                            height={14}
                                        />
                                    </IconButton>
                                )}
                            </ListItem>
                        );
                    }

                    return (
                        <ListItem
                            key={url?.url ?? ""}
                            component={m.div}
                            {...varFade().inRight}
                            sx={{
                                my: 1,
                                px: 2,
                                py: 0.75,
                                borderRadius: 0.75,
                                border: (theme) => `solid 1px ${theme.palette.divider}`,
                            }}
                        >
                            <Iconify icon={'eva:file-fill'}
                                     sx={{width: 28, height: 28, color: 'text.secondary', mr: 2}}/>

                            {/*<ListItemText
                primary={typeof file === 'string' ? file : name}
                secondary={typeof file === 'string' ? '' : fData(size || 0)}
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondaryTypographyProps={{ variant: 'caption' }}
              />*/}

                            {onRemove && (
                                <IconButton edge="end" size="small" onClick={() => onRemove(url?.url)}>
                                    <Iconify icon={'eva:close-fill'}/>
                                </IconButton>
                            )}
                        </ListItem>
                    );
                })}
            </AnimatePresence>
        </List>
    );
}
