import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {Box, Link, Typography} from '@mui/material';
import MyAvatar from '../../../components/MyAvatar';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';

const RootStyle = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  // @ts-ignore
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------


interface NavbarAccountType {
  isCollapse?: boolean
}


export default function NavbarAccount({isCollapse}: NavbarAccountType) {
  
  const { user } = useAuth()

  return (
    <Link underline="none" color="inherit" component={RouterLink} to={"/app/home"}>
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <MyAvatar/>

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {user?.firstName} {user?.lastName}
          </Typography>
          <Typography variant="body2" noWrap sx={{color: 'text.secondary'}}>
            Administrateur
          </Typography>
        </Box>
      </RootStyle>
    </Link>
  );
}
