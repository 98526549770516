import {LazyLoadImage} from 'react-lazy-load-image-component';
import {Box, Tooltip} from '@mui/material';
import {Iconify} from "components";
import {useTranslation} from "react-i18next";

interface ImageType {
    disabledEffect?: boolean;
    effect?: string;
    ratio?: '4/3' | '3/4' | '6/4' | '4/6' | '16/9' | '9/16' | '21/9' | '9/21' | '1/1';
    sx?: object;
    visibleByDefault?: any;
    alt: string,
    src: string,
    onClick?: any,
    is_optimize?: boolean,
}

export default function Image(
    {
        ratio,
        disabledEffect = false,
        effect = 'blur',
        onClick,
        sx,
        alt,
        is_optimize = false,
        ...other
    }: ImageType) {
    const {t} = useTranslation();
    if (ratio) {
        return (
            <Box
                onClick={onClick}
                component="span"
                sx={{
                    width: 1,
                    lineHeight: 0,
                    display: 'block',
                    overflow: 'hidden',
                    position: 'relative',
                    pt: getRatio(ratio),
                    '& .wrapper': {
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        lineHeight: 0,
                        position: 'absolute',
                        backgroundSize: 'cover !important',
                    },
                    ...sx,
                }}
            >
                {/*@ts-ignore*/}
                <Box
                    component={LazyLoadImage}
                    wrapperClassName="wrapper"
                    effect={disabledEffect ? undefined : effect}
                    placeholderSrc={disabledEffect ? '/static/transparent.png' : '/static/images/placeholder.svg'}
                    sx={{width: 1, height: 1, objectFit: 'cover'}}
                    {...other}
                />
            </Box>
        );
    }

    return (
        <Box
            component="span"
            sx={{
                lineHeight: 0,
                display: 'block',
                overflow: 'hidden',
                position: 'relative',
                '& .wrapper': {width: 1, height: 1, backgroundSize: 'cover !important'},
                ...sx,
            }}
        >
            {
                is_optimize && (
                    <Tooltip
                        title={t('image_optimised_lable')}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                zIndex: 1,
                                backgroundColor: "#fff",
                                borderRadius: "50%",
                                width: 30,
                                height: 30,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: 'center',
                                flexDirection: "column"
                            }}
                        >
                            <Iconify icon={"flat-color-icons:flash-on"}/>
                        </div>

                    </Tooltip>
                )
            }

            {/*@ts-ignore*/}
            <Box
                component={LazyLoadImage}
                wrapperClassName="wrapper"
                effect={disabledEffect ? undefined : effect}
                placeholderSrc="/assets/placeholder.svg"
                sx={{width: 1, height: 1, objectFit: 'cover'}}
                {...other}
            />
        </Box>
    );
}

// ----------------------------------------------------------------------

function getRatio(ratio = '1/1') {
    return {
        '4/3': 'calc(100% / 4 * 3)',
        '3/4': 'calc(100% / 3 * 4)',
        '6/4': 'calc(100% / 6 * 4)',
        '4/6': 'calc(100% / 4 * 6)',
        '16/9': 'calc(100% / 16 * 9)',
        '9/16': 'calc(100% / 9 * 16)',
        '21/9': 'calc(100% / 21 * 9)',
        '9/21': 'calc(100% / 9 * 21)',
        '1/1': '100%',
    }[ratio];
}
