import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';

interface IconifyProps {
  icon: React.ReactNode | string;
  sx?: object;
  width?: number|string;
  height?: number|string;
  color?: string;
}

const Iconify = React.forwardRef(function (props: IconifyProps, ref: any) {
  const { icon, sx, width, height, color } = props;
  return (
    <Box ref={ref} sx={sx}>
      {/* @ts-ignore */}
      <Icon color={color} icon={icon} width={width} height={height} />
    </Box>
  );
});

export default Iconify;
