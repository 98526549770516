import React, { createContext, useState } from 'react';
import { User } from 'services/queries';

interface AuthLogin {
    accessToken: string | undefined | null;
    user: User | undefined | null;
}

interface AuthContextProps {
    isAuthenticated: boolean;
    accessToken: string | null | undefined;
    user: User | undefined | null;
    loginUser: (authLogin: AuthLogin) => void;
    logoutUser: () => void;
}

const initialProps: AuthContextProps = {
    isAuthenticated: false,
    accessToken: null,
    user: null,
    loginUser: (authLogin: AuthLogin) => { },
    logoutUser: () => { },
}

const AuthContext = createContext(initialProps);

function AuthProvider({ children, authLogin }: { children: React.ReactNode, authLogin: AuthLogin }) {

    const [state, setState] = useState({
        isAuthenticated: !!authLogin.accessToken,
        ...authLogin
    })

    // Mode

    const loginUser = (authLogin: AuthLogin) => {
        setState({
            isAuthenticated: !!authLogin.accessToken,
            ...authLogin
        });
    };

    const logoutUser = () => {
        setState({
            isAuthenticated: false,
            accessToken: null,
            user: null
        });
    };


    return (
        <AuthContext.Provider
            value={{
                ...state,
                // Actions
                loginUser,
                logoutUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export { AuthProvider, AuthContext };
