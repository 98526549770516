import useAuth from 'hooks/useAuth';
import { Redirect } from 'react-router-dom';

function AuthGuard(props: any) {
  const { children } = props;
  const { isAuthenticated } = useAuth();
  
  if (!isAuthenticated) {
    return <Redirect to="/auth/login" />;
  }

  return children;
}

export default AuthGuard;