import createAvatar from '../utils/createAvatar';
import Avatar from './Avatar';

export default function MyAvatar({...other}: any) {

  const user: any = {}

  return (
    <Avatar
      src={user?.avatar?.url}
      alt={`${user?.firstname ?? ""} ${user?.lastname ?? ""}`}
      color={user?.avatar?.url ? 'default' : createAvatar(`${user?.firstname ?? ""} ${user?.lastname ?? ""}`).color}
      {...other}
    >
      {createAvatar(`${user?.firstname ?? ""} ${user?.lastname ?? ""}`).name}
    </Avatar>
  );
}
