import React, { ReactNode, createContext, useState } from 'react';
import { ArticleGetQuery } from 'services/queries';


export interface ArticleContextProps{
      articlesState:boolean,
      setArticlesState:React.Dispatch<React.SetStateAction<boolean>>
}

export const ArticleContext = createContext<ArticleContextProps|null>(null)

export const ArticleProvider=({children}:{children:ReactNode})=>{
     const [articlesState, setArticlesState]=useState(false)
     const value= {articlesState, setArticlesState};

     return <ArticleContext.Provider value={value}>{children}</ArticleContext.Provider>
}



