import { createContext, useContext, useEffect } from 'react';
import {
  EstimatesNotification,
  OrdersNotification,
  useDeleteAllNotificationsMutation,
  useDeleteEstimatesNotificationsMutation,
  useDeleteOrdersNotificationsMutation,
  useEstimatesNotificationsGetQuery,
  useOrdersNotificationsGetQuery,
} from 'services/queries';

const initialState = {
  ordersNotifications: [] as OrdersNotification[],
  estimatesNotifications: [] as EstimatesNotification[],
  removeAllNotification: () => {},
  removeOrderNotification: (notificationUuid: string) => {},
  removestimateNotification: (notificationUuid: string) => {},
  refetchOrderNotifications: () => {},
  refetchEstimateNotification: () => {},
};

const NotificationContext = createContext(initialState);

function useNotification() {
  return useContext(NotificationContext);
}

function NotificationProvider(props: any) {
  const { refetch: refetchOrderNotifications, ...orderData } =
    useOrdersNotificationsGetQuery({
      variables: {},
    });
  const { refetch: refetchEstimateNotification, ...estimateData } =
    useEstimatesNotificationsGetQuery();
  const [deleteAllNotifications, { loading }] =
    useDeleteAllNotificationsMutation();
  const [deleteOrdersNotifications, { loading: deleteOrderLoading }] =
    useDeleteOrdersNotificationsMutation();
  const [deleteEstimatesNotifications, { loading: deleteEstimateLoading }] =
    useDeleteEstimatesNotificationsMutation();

  useEffect(() => {
    const fetchNotification = async () => {
      await refetchOrderNotifications();
      await refetchEstimateNotification();
    };
    const subscribe = setInterval(() => {
      fetchNotification();
    }, 1000 * 60);

    return () => {
      clearInterval(subscribe);
    };
  }, [loading, deleteOrderLoading, deleteEstimateLoading]);

  const removeOrderNotification = async (uuid: string) => {
    await deleteOrdersNotifications({
      variables: {
        uuid,
      },
    });
  };

  const removestimateNotification = async (uuid: string) => {
    await deleteEstimatesNotifications({
      variables: {
        uuid,
      },
    });
  };
  return (
    <NotificationContext.Provider
      value={{
        ordersNotifications:
          orderData.data?.ordersNotificationsGet ?? ([] as any),
        estimatesNotifications:
          estimateData.data?.estimatesNotificationsGet ?? ([] as any),
        removeAllNotification: deleteAllNotifications,
        removeOrderNotification,
        removestimateNotification,
        refetchEstimateNotification,
        refetchOrderNotifications,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
}

export { NotificationProvider, NotificationContext, useNotification };
