import React from 'react';
import {Paper, SxProps, Theme, Typography} from '@mui/material';

interface SearchNotFoundProps {
    query: string;
    sx?: SxProps<Theme>
}

export default function SearchNotFound(props: SearchNotFoundProps) {
    const {query, sx, ...other} = props;
    return query ? (
        <Paper
            sx={{
                textAlign: 'center',
                ...sx,
            }}
            {...other}
        >
            <Typography variant="h6" paragraph>
                Non trouvé
            </Typography>

            <Typography variant="body2">
                Aucun résultat trouvé pour &nbsp;
                <strong>&quot;{query}&quot;</strong>.
                <br/> Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.
            </Typography>
        </Paper>
    ) : (
        <Typography variant="body2" sx={sx}>
            Veuillez entrer un mot clé pour rechercher.
        </Typography>
    );
}
