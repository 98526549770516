import { purple } from '@mui/material/colors';
import {alpha} from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
    return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const PRIMARY = {
    main: '#00CCFF',
    contrastText: '#fff',
    light: '#7F8DA5',
    dark: '#68E0FF',
    darker: '#68E0FF',
};
const SECONDARY = {
    main: '#00abd5',
    contrastText: '#fff'
};
const INFO = {
    lighter: '#ccf1fd',
    light: '#74CAFF',
    main: '#1890FF',
    dark: '#0C53B7',
    darker: '#04297A',
};
const SUCCESS = {
    lighter: '#E9FCD4',
    light: '#AAF27F',
    main: '#9BDEA6',
    dark: '#229A16',
    darker: '#08660D',
};
const WARNING = {
    lighter: '#FFF7CD',
    light: '#FFE16A',
    main: '#FFC107',
    dark: '#B78103',
    darker: '#7A4F01',
};

const ERROR = {
    lighter: '#FFE7D9',
    light: '#FFA48D',
    main: '#F67676',
    dark: '#B72136',
    darker: '#7A0C2E',
};
const PURPLE={
 main:'#A074B1',
 light:'#e6d6ec',
 dark:'#6a158b'
};

const PINK={
    main:'#FF6C6C',
    light:'#FFE7D9',
    dark:'#f47e7e'
};
const CUSTUM_ORANGE={
    main:'#FF8A00',
    light:'#ffddb6',
    dark:'#FF8A00'
};
const CUSTUM_GREY={
    main:'#CAD2DE',
    light:'#e9eaec',
    dark:'#8d9094'
}


const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#E5E5E5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
    1000: '#CAD2DE',
    500_8: alpha('#919EAB', 0.08),
    500_12: alpha('#919EAB', 0.12),
    500_16: alpha('#919EAB', 0.16),
    500_24: alpha('#919EAB', 0.24),
    500_32: alpha('#919EAB', 0.32),
    500_48: alpha('#919EAB', 0.48),
    500_56: alpha('#919EAB', 0.56),
    500_80: alpha('#919EAB', 0.8),
};

const GRADIENTS = {
    primary: createGradient(PRIMARY.light, PRIMARY.main),
    info: createGradient(INFO.light, INFO.main),
    success: createGradient(SUCCESS.light, SUCCESS.main),
    warning: createGradient(WARNING.light, WARNING.main),
    error: createGradient(ERROR.light, ERROR.main),
    purple: createGradient(PURPLE.light, PURPLE.main),
    pink:createGradient(PINK.light, PINK.main),
    custum_orange:createGradient(PINK.light, PINK.main),
    custum_grey:createGradient(CUSTUM_GREY.light, CUSTUM_GREY.main)
};

const CHART_COLORS = {
    violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
    blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
    green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
    yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
    red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
    common: {black: '#000', white: '#fff'},
    primary: {...PRIMARY,},
    secondary: {...SECONDARY,},
    info: {...INFO, contrastText: '#fff'},
    success: {...SUCCESS, contrastText: GREY[800]},
    warning: {...WARNING, contrastText: GREY[800]},
    error: {...ERROR, contrastText: '#fff'},
    purple:{...PURPLE, contrastText:GREY[800]},
    pink:{...PINK, contrastText:GREY[800]},
    custum_orange:{...CUSTUM_ORANGE, contrastText:GREY[800]},
    custum_grey:{...CUSTUM_GREY, contrastText:GREY[800]},
    grey: GREY,
    gradients: GRADIENTS,
    chart: CHART_COLORS,
    divider: GREY[500_24],
    action: {
        hover: GREY[500_8],
        selected: GREY[500_16],
        disabled: GREY[500_80],
        disabledBackground: GREY[500_24],
        focus: GREY[500_24],
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
    }
};

const palette = {
    light: {
        ...COMMON,
        mode: 'light',
        text: {
            primary: '#001F27',
            secondary: "#4F4F4F",
            disabled: GREY[500],
            white: '#fff',
            grey: '#9F9996'
        },
        background: {paper: '#fff', default: '#fff', neutral: GREY[200]},
        action: {active: GREY[600], ...COMMON.action},
    },
    dark: {
        ...COMMON,
        mode: 'dark',
        text: {primary: '#fff', secondary: GREY[500], disabled: GREY[600], grey: '#9F9996'},
        background: {paper: "#001F27", default: "#001F27"},
        action: {active: GREY[500], ...COMMON.action},
    }
};

export default palette;
