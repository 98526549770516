// @mui
import { Box, SxProps, Theme } from '@mui/material';
import { fileData, fileFormat, fileThumb } from './utils';
import { Storage } from "services/queries";


interface FileThumbnailProps {
    sx?: SxProps<Theme> | undefined;
    imgSx?: SxProps<Theme> | undefined;
    imageView?: boolean;
    file: Storage;
}

export default function FileThumbnail(props: FileThumbnailProps) {
    const { file, imageView, sx, imgSx } = props
    const { name = '', path = '', preview = '' } = fileData(file);

    const format = fileFormat(path || preview);

    const renderContent =
        format === 'image' && imageView ? (
            <Box
                component="img"
                src={preview}
                sx={{
                    width: 1,
                    height: 1,
                    flexShrink: 0,
                    objectFit: 'cover',
                    ...imgSx,
                }}
            />
        ) : (
            <Box
                component="img"
                src={fileThumb(format)}
                sx={{
                    width: 32,
                    height: 32,
                    flexShrink: 0,
                    ...sx,
                }}
            />
        );

    return (
        <>
            {renderContent}

        </>
    );
}
