import useAuth from 'hooks/useAuth';
import { Redirect } from 'react-router-dom';

function GuestGuard(props: any) {
  const { children } = props;
  const { isAuthenticated } = useAuth();
  
  if (isAuthenticated) {
    return <Redirect to="/app/home" />;
  }
  return children;
}

export default GuestGuard;
