/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { Fragment, lazy, Suspense } from 'react';
import AuthLayout from 'layouts/Auth';
import ApplicationLayout from 'layouts/Application';
import { RouteConfig } from 'react-router-config';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LoadingScreen } from 'components';
import AuthGuard from './AuthGuard';
import GuestGuard from './GuestGuard';

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/app/home" />,
  },
  {
    path: '/errors/error-404',
    exact: true,
    component: lazy(() => import('views/errors/error.page')),
  },
  {
    path: '/auth',
    layout: AuthLayout,
    guard: GuestGuard,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/auth/login/Login.page')),
      },
      {
        path: '/auth/reset-password',
        exact: true,
        component: lazy(
          () => import('views/auth/reset_password/resetPassword.page'),
        ),
      },
      {
        path: '*',
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: '/app',
    layout: ApplicationLayout,
    guard: AuthGuard,
    routes: [
      {
        path: '/app/home',
        exact: true,
        component: lazy(() => import('views/home/Home.page')),
      },
      {
        path: '/app/error-404',
        exact: true,
        component: lazy(() => import('views/errors/error.page')),
      },
      {
        path: '/app/users',
        exact: true,
        component: lazy(() => import('views/users/index')),
      },
      {
        path: '/app/medias',
        exact: true,
        component: lazy(() => import('views/media/index')),
      },

      {
        path: '/app/notifications',
        exact: true,
        component: lazy(() => import('views/notifications/index')),
      },
      {
        path: '/app/orders',
        exact: true,
        component: lazy(() => import('views/order/index')),
      },
      {
        path: '/app/orders/:uuid',
        exact: true,
        component: lazy(() => import('views/order/detail')),
      },
      {
        path: '/app/quotes',
        exact: true,
        component: lazy(() => import('views/quotes/index')),
      },
      {
        path: '/app/products',
        exact: true,
        component: lazy(() => import('views/products/index')),
      },
      {
        path: '/app/product/:uuid',
        exact: true,
        component: lazy(() => import('views/products/detail')),
      },
      {
        path: '/app/studio-squid/background',
        exact: true,
        component: lazy(() => import('views/studio_squid/background')),
      },
      {
        path: '/app/studio-squid/pictogramme',
        exact: true,
        component: lazy(() => import('views/studio_squid/pictograme')),
      },
      {
        path: '/app/studio-squid/shape',
        exact: true,
        component: lazy(() => import('views/studio_squid/shape')),
      },
      {
        path: '/app/studio-squid/template',
        exact: true,
        component: lazy(() => import('../views/studio_squid/templatePage')),
      },
      {
        path: '/app/studio-squid/image',
        exact: true,
        component: lazy(() => import('views/studio_squid/image')),
      },
      {
        path: '/app/settings/faq',
        exact: true,
        component: lazy(() => import('views/settings/faq/Faq.page')),
      },
      {
        path: '/app/settings/page-content',
        exact: true,
        component: lazy(() => import('views/settings/pages-bottom-content/ListPages.page')),
      },
      {
        path: '/app/settings/page-content/:slug',
        exact: true,
        component: lazy(() => import('views/settings/pages-bottom-content/SinglePage.page')),
      },
      {
        path: '/app/settings/fonts',
        exact: true,
        component: lazy(() => import('views/settings/fonts/Font.page')),
      },
      {
        path: '/app/settings/others',
        exact: true,
        component: lazy(() => import('views/settings/others/index')),
      },
      {
        path: '/app/settings/images',
        exact: true,
        component: lazy(() => import('views/settings/images/index')),
      },
      {
        path: '/app/profile',
        exact: true,
        component: lazy(() => import('views/profil/index')),
      },
      {
        path: '/app/coupon',
        exact: true,
        component: lazy(() => import('views/coupons/index')),
      },
      {
        path: '/app/avis',
        exact: true,
        component: lazy(() => import('views/opinion/index')),
      },
      {
        path: '/app/articles',
        exact: true,
        component: lazy(() => import('views/articles/index')),
      },
      {
        path: '/app/articles/add',
        exact: true,
        component: lazy(() => import('views/articles/add')),
      },
      {
        path: '/app/article/:id',
        exact: true,
        component: lazy(() => import('views/articles/details')),
      },
      {
        path: '/app/article/:id/edit',
        exact: true,
        component: lazy(() => import('views/articles/edit')),
      },
      {
        path: '/app/categorie',
        exact: true,
        component: lazy(() => import('views/categorie/index')),
      },

      {
        path: '*',
        component: () => <Redirect to="/app/error-404" />,
      },
    ],
  },
];

const renderRoutes = (routes: RouteConfig[]) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component: any = route.component;
          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
