// components
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  media: getIcon('ic_media'),
  peoples: getIcon('ic_people'),
  settings: getIcon('ic_settings'),
  product: getIcon('ic_product'),
  tag: getIcon('ic_tag'),
  category: getIcon('ic_category'),
  percent: getIcon('ic_percent'),
  coupon: getIcon('ic_coupon'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  media: getIcon('ic_media'),
  peoples: getIcon('ic_people'),
  settings: getIcon('ic_settings'),
  product: getIcon('ic_product'),
  tag: getIcon('ic_tag'),
  category: getIcon('ic_category'),
  percent: getIcon('ic_percent'),
  background: getIcon('ic_background'),
  picto: getIcon('ic_picto'),
  image: getIcon('ic_image'),
  opinion: getIcon('ic_chat')
};

const navConfig = [
  {
    subheader: 'menu_group_general',
    items: [
      { title: 'menu_item_app', path: '/app/home', icon: ICONS.dashboard },
    ],
  },
  {
    subheader: 'menu_group_blog',
    items: [
      {
        title: 'menu_item_article',
        path: '/app/articles',
        icon: ICONS.blog,
      },
      {
        title: 'menu_item_categorie',
        path: '/app/categorie',
        icon: ICONS.category,
      },
    ],
  },
  {
    subheader: 'ecommerce',
    items: [
      {
        title: 'menu_item_products',
        path: '/app/products',
        icon: ICONS.product,
        children: [{ title: 'menu_item_products', path: '/app/products' }],
      },
      {
        title: 'menu_item_orders',
        path: '/app/orders',
        icon: ICONS.cart,
      },
      {
        title: 'menu_item_media',
        path: '/app/medias',
        icon: ICONS.media,
      },
      {
        title: 'menu_item_quotes',
        path: '/app/quotes',
        icon: ICONS.percent,
      },
      {
        title: 'menu_item_coupons',
        path: '/app/coupon',
        icon: ICONS.coupon,
      },
      {
        title: 'menu_item_opinion',
        path: '/app/avis',
        icon: ICONS.opinion,
      },
    ],
  },
  {
    subheader: 'studio squid',
    items: [
      // {
      //   title: 'menu_item_background',
      //   path: '/app/studio-squid/background',
      //   icon: ICONS.background,
      // },
      {
        title: 'menu_item_picto',
        path: '/app/studio-squid/pictogramme',
        icon: ICONS.picto,
      },
      // {
      //   title: 'translate_key_template',
      //   path: '/app/studio-squid/template',
      //   title: 'translate_key_template',
      //   path: '/app/studio-squid/template',
      //   icon: ICONS.invoice,
      // },
      // {
      //   title: 'translate_key_shape',
      //   path: '/app/studio-squid/shape',
      //   icon: ICONS.picto,
      // },
      {
        title: 'menu_item_image',
        path: '/app/studio-squid/image',
        icon: ICONS.image,
      },
    ],
  },
  {
    subheader: 'management',
    items: [
      {
        title: 'menu_item_newsletters',
        path: '/app/newsletters',
        icon: ICONS.mail,
      },
      {
        title: 'menu_item_users',
        path: '/app/users',
        icon: ICONS.peoples,
      },
      {
        title: 'menu_item_notifications',
        path: '/app/notifications',
        icon: <Iconify icon="eva:bell-fill" width={20} height={20} />,
      },
      {
        title: 'menu_item_settings',
        path: '/app/settings',
        icon: ICONS.settings,
        children: [
          {
            title: 'navigation_item_faq',
            path: '/app/settings/faq',
          },
          {
            title: 'navigation_item_font',
            path: '/app/settings/fonts',
          },
          {
            title: 'page_bottom_content_title',
            path: '/app/settings/page-content',
          },
          // {
          //   title: 'menu_item_image_settings',
          //   path: "/app/settings/images",
          // },
          {
            title: 'other_settings_page_title',
            path: '/app/settings/others',
          },
        ],
      },
    ],
  },
];

export default navConfig;
