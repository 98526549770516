import { Avatar, Box, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import moment from 'moment';
import { EstimatesNotification, OrdersNotification } from 'services/queries';
import getInitials from 'utils/getInitials';
interface NotificationDataProps {
  // type: 'order' | 'estimate';
  // estimate?: EstimatesNotification;
  // order?: OrdersNotification;
  notification: any;
}
const Notification = ({ notification }: NotificationDataProps) => {
  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        color: 'white',
        fontSize: 13,
      },
      children: getInitials(name),
    };
  }
  return (
    <Paper elevation={2}>
      <Stack flexDirection={'row'} alignItems={'center'} p={2} columnGap={4}>
        <Avatar {...stringAvatar(notification.__typename ?? '')} />
        <Box width={'100%'}>
          <Typography>
            {notification.__typename === 'Estimate'
              ? 'Nouveau devis'
              : 'Nouvelle commande'}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {notification.__typename === 'Estimate'
              ? notification?.company_name
              : notification?.order_code}
          </Typography>
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            mt={1}
          >
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {notification.__typename === 'Estimate'
                ? moment(notification?.createdAt ?? new Date()).format('ll')
                : moment(notification?.createdAt ?? new Date()).format('ll')}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {notification.__typename === 'Estimate'
                ? moment(notification?.createdAt ?? new Date()).format('HH:mm')
                : moment(notification?.createdAt ?? new Date()).format('HH:mm')}
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
};

export default Notification;
