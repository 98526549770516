import {CircularProgress, TableCell, TableRow} from '@mui/material';
import EmptyContent from '../EmptyContent';
import {styled} from '@mui/material/styles';
import {useTranslation} from "react-i18next";
//

const RootStyle = styled('div')(({theme}) => ({
    height: '100%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(8, 2),
}));


interface TableNoDataType {
    isNotFound?: boolean,
    isLoading?: boolean,
    message?: string,
}

export default function TableNoData({isNotFound, isLoading, message}: TableNoDataType) {
    const {t} = useTranslation();

    return (
        (isNotFound || isLoading) ? <TableRow>
            {isNotFound && !isLoading ? (
                <TableCell colSpan={12}>
                    <EmptyContent
                        description={""}
                        title={message ? t(message) : t('empty_data_user_messages')}
                        sx={{
                            '& span.MuiBox-root': {height: 160},
                        }}
                    />
                </TableCell>
            ) : (
                <TableCell colSpan={12} sx={{p: 0}}>
                    {isLoading && <RootStyle>
                        <CircularProgress/>
                    </RootStyle>}
                </TableCell>
            )}
        </TableRow> : <></>
    );
}

