export default {
  translation: {
    'shared.french': 'Francais',
    'shared.english': 'Anglais',
    field_require: 'Champ requis',
    login_page_action: 'Se connecter',
    login_page_title: 'Connexion',
    login_form_email: 'Email',
    login_form_phone: 'Téléphone',
    validate_choice_with_enter: 'Appuyer sur entrée pour ajouter une valeur',
    'shared.internet_connexion_error':
      " Une erreur inconnue s'est produite. Veuillez ressayer plus tard",
    login_form_password: 'Mot de passe',
    login_form_require: 'Champ requis',
    unable_to_disconnect: 'Impossible de se déconnecter',
    login_form_type_error: 'Veuillez entrer un numéro valide',
    login_page_subtitle: 'Veuillez remplir le formulaire ci-dessous',
    login_page_forgot_password: 'Mot de passe oublié',
    field_type_number_error: 'Veuillez saisir un nombre',
    navigation_item_users: 'Utilisateurs',
    navigation_item_suppliers: 'Fournisseurs',
    error_message_page: 'Page Introuvable',
    cause_error_not_found:
      "Cette page est en cours de construction  ou n'existe pas",
    go_back_to_home: "Retour à l'accueil",
    topbar_profile_logout: 'Déconnexion',
    topbar_profile_profil: 'Profil',
    profile_page_user_avatar: 'Photo de profil',
    label_update_photo: 'Mettre à jour la photo',
    file_limit_upload_photo: 'Autorisé *.jpeg, *.jpg, *.png, *.gif',
    file_size_upload_photo: 'Taille maximale de',
    review_dialog_action_next: 'Suivant',
    review_dialog_action_preview: 'Précédent',
    email_form_invalid: 'Veuillez saisir une adresse email valide',
    register_field_code: 'Code',
    home_page_title: 'Tableau de board',
    label_add_user: 'Nouvel utilisateur',
    table_head_label_name: 'Nom',
    table_head_label_email: 'Email',
    table_head_label_phonenumber: 'Téléphone',
    table_head_label_address: 'Adresse',
    table_head_label_delivring_time: 'Délai de livraison',
    table_head_label_delivring_time_placeholder: '1 jour',
    table_head_label_role: 'Rôle',
    table_head_label_status: 'Statut',
    table_head_label_last_login: 'Dernière connexion',
    user_status_UNVALIDATED: 'Non validé',
    user_status_ACTIVED: 'Activé',
    user_status_UNACTIVED: 'Inactif',
    user_status_DELETED: 'Supprimé',
    global_action_edit: 'Modifier',
    global_action_change: 'Changer de statut',
    global_action_delete: 'Supprimer',
    global_action_detail: 'Détail',
    global_label_all: 'Tous',
    global_label_search: 'Recherche...',
    global_label_add_user: 'Nouvel utilisateur',
    global_label_user_detail: 'Détail utilisateur',
    global_label_add_supplier: 'Nouveau fournisseur',
    global_menu_item_dashboard: 'Tableau de bord',
    page_user_list: 'Liste des utilisateurs',
    page_supplier_list: 'Liste des fournisseurs',
    field_email_error: 'Veuillez saisir une adresse mail valide',
    admin_page_user_name: 'Nom(s)',
    admin_page_user_surname: 'Prénom(s)',
    admin_page_supplier_address: 'Adresse',
    admin_page_user_email: 'Email',
    add_user_modal_title: 'Nouvel utilisateur',
    add_discount_modal_title: 'Nouveau coupon',
    update_user_modal_title: "Modifier l'utilisateur USERNAME",
    update_discount_modal_title: 'Modifier le Coupon ',
    discount_delete_success: 'Coupon supprimer avec succès',
    update_use_profile_modal_title: 'Modifier mon Profil',
    user_profile_update_success: 'Profil utilisateur mis a jour avec succès  ',
    update_suppler_modal_title: 'Modifier le fournisseur USERNAME',
    global_label_save: 'Enregistrer',
    global_label_cancel: 'Annuler',
    user_add_success: 'Utilisateur ajouté avec succès',
    global_label_confirm: 'Confirmer',
    delete_user_dialog_title: 'Supprimer un utilisateur',
    delete_supplier_dialog_title: 'Supprimer un fournisseur',
    change_user_dialog_title: "Changer le statut d'un utilisateur",
    dialog_confirm_delete_users:
      'Souhaitez-vous vraiment supprimer cet utilisateur ?',
    dialog_confirm_delete_supplier:
      'Souhaitez-vous vraiment supprimer ce fournisseur ?',
    dialog_confirm_change_users: 'Souhaitez-vous vraiment changer le statut',
    dialog_confirm_change_users_statuts: 'de cet utilisateur',
    user_delete_success: 'Utilisateur supprimé',
    empty_data_user_messages: 'Aucun utilisateur',
    login_welcome_message: 'Bonjour, bienvenue',
    login_cta_title: 'Se connecter à Adsquid',
    login_cta_subtitle: 'Entrez vos coordonnées ci-dessous.',
    user_update_success: 'Utilisateur mis à jour',
    menu_group_setting: 'Paramètres',
    menu_group_general: 'Général',
    menu_item_media: 'Médias',
    menu_item_quotes: 'Devis',
    menu_item_products: 'Produits',
    menu_item_galery: 'Galerie',
    menu_item_categories: 'Catégories',
    menu_item_supplier: 'Fournisseurs',
    menu_item_tags: 'Etiquettes',
    menu_item_orders: 'Commandes',
    menu_item_background: 'Background',
    menu_item_picto: 'Pictogrammes',
    menu_item_image: 'Metiers',
    menu_item_opinion: 'Avis ',
    menu_item_coupon_code: 'Codes promo',
    menu_item_app: 'Tableau de bord',
    menu_item_users: 'Utilisateurs',
    menu_item_settings: 'Paramètres',
    menu_item_newsletters: 'Newsletters',
    menu_item_statistics: 'Statistiques',
    menu_item_menus: 'Menus de la boutique',
    menu_item_pages: 'Pages de la boutique',
    menu_item_menus_header: 'Entête de page',
    menu_item_menus_footer: 'Pied de page',
    global_action_update: 'Mettre à jour',
    global_action_add: 'Ajouter',
    asset_drop_zone_label: 'Cliquer ici pour ajouter des fichiers',
    asset_drop_zone_label_mediatheque:
      'Cliquer ici pour choisir un fichier ou une icône',
    choose_new_status_user: 'Veuillez choisir le nouveau statut',
    asset_drop_zone_file_error: 'Veuillez choisir une image ou une video.',
    dialog_confirmation: 'Confirmation',
    dialog_action_confirm: 'Confirmer',
    dialog_action_cancel: 'Annuler',
    dialog_confirm_change_mediatheque:
      'Voulez-vous vraiment supprimer cet élément de la médiathèque ?',
    menu_item_sub_categories: 'Sous catégories',
    add_category: 'Ajouter une catégorie',
    add_sub_category: 'Ajouter une catégorie',
    category: 'Catégorie',
    category_page_select_parent_category: 'Catégorie parente',
    category_name: 'Nom',
    category_description: 'Description',
    category_description_fr: 'Description en français',
    category_description_en: 'Description en anglais',
    category_slug: 'Slug',
    category_total: 'Nombres produits',
    category_sub_category_total: 'Sous categories',
    category_title_fr: 'Titre français',
    category_title_en: 'Titre anglais',
    create_category: 'Créer la catégorie',
    category_id: 'ID',
    update_category: 'Modifier la catégorie CATEGORY_NAME',
    update_sub_category: 'Modifier la sous catégorie SUB_CATEGORY_NAME',
    delete_category_dialog_title: 'Supprimer la catégorie',
    dialog_confirm_delete_category:
      'Êtes vous sûrs de vouloir supprimer la catégorie CATEGORY_NAME',
    sub_category_name: 'Nom',
    sub_category_description: 'Description',
    sub_category_description_fr: 'Description en français',
    sub_category_description_en: 'Description en anglais',
    sub_category_slug: 'Slug',
    sub_category_total: 'Nombres produits',
    sub_category_sub_category_total: 'Sous categories',
    sub_category_title_fr: 'Titre français',
    sub_category_title_en: 'Titre anglais',
    create_sub_category: 'Créer la sous catégorie',
    sub_category_id: 'ID',
    delete_sub_category_dialog_title: 'Supprimer la sous catégorie',
    dialog_confirm_delete_sub_category:
      'Êtes vous sûrs de vouloir supprimer la sous catégorie SUB_CATEGORY_NAME',
    category_list_is_empty: 'Aucune catégorie pour le moment',
    homepage_welcome_message: 'Bienvenue!',
    homepage_welcome_statics:
      'Les ventes sur la dernière semaine ont progressé de PERCENT%.',
    homepage_see_orders: 'Voir les commandes',
    homepage_new_product: 'Nouveau',
    homepage_see_product: 'Afficher',
    homepage_product_sold: 'Produit vendu',
    homepage_than_last_month: 'que le mois dernier',
    homepage_total_balance: 'Solde total',
    homepage_sales_profit: 'Bénéfice des ventes',
    homepage_sale_by_gender: 'Vente par catégorie',
    homepage_mens: 'Vestes',
    homepage_womens: 'Accessoires',
    homepage_yearly_sales: 'Ventes annuelles',
    homepage_yearly_sales_revenu: 'Revenu de vente',
    homepage_than_last_year: "que l'année précédente",
    product_page_title: 'Liste des produits',
    product_page_add_new: 'Nouveau produit',
    product_page_search_product: 'Rechercher un  produit...',
    product_page_name: 'Nom',
    product_page_code: 'Code',
    product_page_create_at: 'Date de création',
    product_page_status: 'Statut',
    product_page_price: 'Prix',
    product_page_edit: 'Éditer',
    product_page_delete: 'Supprimer',
    product_page_rows_per_page: 'Produits par page',
    users_page_rows_per_page: 'Utilisateurs par page',
    label_displayed_row: 'sur',
    label_displayed_pages: 'Page(s)',
    product_page_dense: 'Densifier',
    product_page_status_sale: 'En vente',
    add_tag: 'Ajouter une étiquette',
    add_sub_tag: 'Ajouter une étiquette',
    tag_page_select_parent_tag: 'Etiquette parente',
    tag_name: 'Nom',
    tag_description: 'Description',
    tag_description_fr: 'Description en français',
    tag_description_en: 'Description en anglais',
    tag_slug: 'Slug',
    tag_total: 'Nombres produits',
    tag_sub_tag_total: 'Sous étiquette',
    tag_title_fr: 'Titre français',
    tag_title_en: 'Titre anglais',
    create_tag: "Créer l'étiquette",
    tag_id: 'ID',
    update_tag: "Modifier l'étiquette TAG_NAME",
    delete_tag_dialog_title: "Supprimer l'étiquette",
    dialog_confirm_delete_tag:
      "Êtes vous sûrs de vouloir supprimer l'étiquette TAG_NAME",
    tag_list_is_empty: 'Aucune étiquette pour le moment',
    page_new_product_title: 'Nouveau produit',
    page_edit_product_title: 'Éditer un produit',
    page_detail_product_title: 'Détails produit',
    product_field_name: 'Nom du produit',
    product_field_description: 'Description',
    product_field_code: 'Code du produit',
    product_field_sku: 'SKU du produit',
    product_field_size: 'Tailles',
    product_field_color: 'Couleurs',
    product_field_provider: 'Fournisseur',
    product_field_categories: 'Catégories',
    page_new_product: 'Ajouter un produit',
    product_field_tags: 'Tags',
    product_field_regular_price: 'Prix régulier',
    product_field_sale_price: 'Prix de vente',
    product_field_cleaning: 'Matière et entretien',
    product_field_detail: 'Détail du produit',
    product_field_size_n_stroke: 'Taille & coupe',
    add_image_from_media: 'Choisir une photo',
    change_image_from_media: 'Changer la photo',
    create_attribute_action: 'Annuler',
    size_image_title: "Dimension de l'image",
    size_image_cancel: 'Réinitialiser',
    maximum_width: 'Largeur maximale',
    maximum_height: 'Hauteur maximale',
    empty_product_img_message: 'Ajouter une image au produit',
    btn_add_product: 'Créer le produit',
    btn_edit_product: 'Modifier le produit',
    dialog_delete_product_title: 'Supprimer un produit',
    dialog_delete_product_description:
      'Voulez vous vraiment supprimer ce produit ? Cette action est irreversible !',
    supplier_delivring_time: 'Jour(s)',
    supplier_delivring_hours: 'Heure(s)',
    detail_product_page_title: "Détail d'un produit",
    detail_product_page_tab_cleaning: 'Matière et entretien',
    detail_product_page_tab_detail: 'Détail du produit',
    detail_product_page_tab_size_and_stroke: 'Taille & coupe',
    detail_product_page_tab_supply: 'Approvisionnement',
    detail_product_page_label_online: 'En ligne',
    detail_product_page_label_offline: 'Hors ligne',
    about: 'A propos',
    global_label_day: 'Jour(s)',
    user_status_success: 'Statut utilisateur mis à jour avec succès',
    purchase_price_label: "Prix d'achat",
    purchase_price_description: "Prix d'achat du produit",
    qty_label: 'Quantité',
    size_label: 'Taille',
    color_label: 'Couleur',
    qty_description: 'Quantité approvisionnée',
    purchase_price_require: "Le prix d'achat est requis",
    qty_require: 'La quantité est requise',
    size_require: 'La taille est requise',
    color_require: 'La couleur est requise',
    add_supply_form: 'Ajouter',
    duplicate_supply_form: 'Dupliquer',
    delete_supply_form: 'Supprimer',
    create_supply_title: 'Créer un approvisionnement',
    select_product_label: 'Sélectionner un produit a provisionner',
    find_product_loading: 'Recherche du produit en cours',
    find_product_empty: 'Aucun produit trouvé',
    product_require: 'Veuillez renseigner le produit a provisionner',
    create_supply_submit_button: 'Approvisionner',
    find_product_find: 'Recherche en cours',
    supply_product_success: 'Produit $product approvisionné avec succès',
    global_label_export: 'Exporter',
    category_row_per_page: 'Catégories par page',
    tags_row_per_page: 'Etiquettes par page',
    category_label_children: 'Enfant(s)',
    global_action_change_theme: 'Changer le thème',
    global_label_change_user_status_title: "Changer le statut de l'utilisateur",
    user_status_description_for_ACTIVED:
      "L'utilisateur est actif et peut se connecter",
    user_status_description_for_UNACTIVED:
      "L'utilisateur est désactivé et ne peut pas se connecter",
    media_label_name: 'Nom',
    media_label_alternative_title: 'Texte alternatif',
    media_label_caption: 'Légende',
    media_label_description: 'Description',
    media_label_url: 'URL du fichier ',
    media_label_url_copied: 'Url Copié',
    media_label_copy_url: "Copier l'URL du fichier",
    media_add_file_success_message: 'Fichier ajouté avec succès',
    media_update_file_success_message: 'Informations enregistrées avec succès',
    media_label_delete_file: 'Supprimer le fichier',
    mediatheque_delete_success: 'Fichier supprimé avec succès',
    product_action_clone: 'Dupliquer',
    duplicate_product_title: 'Duplication effectué avec succès',
    product_status_DRAFT: 'Brouillon',
    product_status_PUBLISH: 'publié',
    in_stock: 'en stock',
    supply_form_error: "Le formulaire d'approvisionnement $i est mal renseigné",
    select_a_color_or_a_size: 'Sélectionner une couleur ou une taille',
    detail_product_page_additional_product: 'Produits additionnels',
    detail_product_page_tab_similar_product: 'Produits similaires',
    add_additional_product: 'Ajouter des produits additionnels',
    add_similar_product: 'Ajouter des produits similaires',
    dialog_delete_additional_product: 'Supprimer un produit additionnel',
    dialog_delete_similar_product: 'Supprimer un produit similaire',
    dialog_delete_additional_product_description:
      'Voulez vous vraiment enlever ce produit de la liste des produits additionnels',
    dialog_delete_similar_product_description:
      'Voulez vous vraiment enlever ce produit de la liste des produits similaires',
    global_add: 'Ajouter',
    order_page_title: 'Liste des commandes',
    command_page_add_new: 'Nouvelle commande',
    order_page_search_order: 'Rechercher une commande...',
    order_page_change_status: 'Changer le statut',
    order_page_product_numbers: 'Nombre de produits',
    order_page_order_status: 'Statut',
    order_page_create_date: 'Date de création',
    order_page_carrier: 'Type de livraison',
    order_page_order_ref: 'N° de référence',
    order_detail_page_order_ref: 'Numéro de commande',
    order_detail_page_delivery_date: 'Livraison prévue le',
    order_detail_page_total_amount: 'Montant total',
    order_detail_page_order_status: 'Statut',
    order_detail_page_show_product: "Voir l'article",
    order_detail_page_product_quantity: 'Quantité',
    order_detail_page_product_size: 'Taille',
    order_detail_page_show_user: "Voir l'utilisateur",
    order_detail_page_title: 'Détail de la commande',
    order_detail_page_sub_title: 'Commande REF_NUMBER',
    order_page_status_PENDING: 'En cours',
    order_page_status_BOOK: 'Livré',
    order_page_status_IN_PROGRESS: 'Livraison en cours',
    order_page_status_PAID: 'Payé',
    page_order_order_no_found: 'Aucune commande',
    discount_page_title: 'Coupons',
    discount_page_coupon_list_title: 'Liste des coupons',
    notice_page_validation: 'Avis et validations',
    discount_page_search_coupon: 'Rechercher des coupons',
    discount_page_code: 'Code',
    discount_page_type: 'Type',
    discount_page_expired_date: "Date d'expiration",
    order_page_is_active: 'Statut',
    order_page_max_used_time: "Nombre d'utilisation maximal",
    order_page_status_active: 'Actif',
    order_page_status_inactive: 'Inactif',
    dialog_delete_coupon_title: 'Supprimer un coupon',
    dialog_delete_coupon_description:
      'Voulez vous vraiment supprimer ce coupon? Cette action est irreversible !',
    order_page_deactivate: 'Désactiver',
    order_page_activate: 'Activer',
    discount_page_update_coupon: 'Mettre à jour',
    type_DISCOUNT: 'Réduction',
    type_PROMOTION: 'Promotion',
    discount_page_not_found: 'Aucun coupon',
    menu_item_slides: 'Slides',
    add_slide_success_message: 'Slide ajouté avec succès',
    update_slide_success_message: 'Slide modifié avec succès',
    add_slide_modal_title: 'Ajouter un slide',
    update_slide_modal_title: 'Mettre à jour un slide',
    menu_item_colors: 'Couleurs',
    add_color_success_message: 'Couleur ajoutée avec succès',
    update_color_success_message: 'Couleur modifiée avec succès',
    add_color_modal_title: 'Ajouter une couleur',
    update_color_modal_title: 'Mettre à jour une couleur',
    menu_item_sizes: 'Tailles',
    menu_item_colors_sizes: 'Couleurs & Tailles',
    add_size_success_message: 'Taille ajoutée avec succès',
    update_size_success_message: 'Taille modifiée avec succès',
    add_menu_bloc_title: 'Titre du bloc',
    add_menu_bloc_button: 'Ajouter un bloc',
    menu_title_input_title: 'Titre',
    menu_title_input_links: 'Lien',
    btn_edit_menu: 'Modifier',
    btn_add_menu: 'Ajouter',
    add_menu_button: 'Lien personnalisé',
    field_required: 'Champ requis',
    incorrect_link: 'Lien incorrect',
    must_be_positive: 'Le nombre doit être positif',
    coupon_page_add_new: 'Nouveau coupon',
    review_page_add_new: 'Nouvel avis',
    notice_updated_success: 'Avis modifier avec succès',
    update_menu_success: 'Menu mis a jour avec succès',
    notice: 'NOTE',
    all_menu_in_bloc_will_be_deleted:
      'Tous les menus liés a ce bloc seront supprimés.',
    rename_bloc_menu: 'Modifier',
    delete_bloc_menu: 'Supprimer',
    delete_bloc_menu_confirm: 'Voulez-vous vraiment supprimer ce bloc',
    slide_title_fr: 'Titre en français',
    slide_title_en: 'Titre en anglais',
    slide_description_fr: 'Description en français',
    slide_description_en: 'Description en anglais',
    slide_btn_text_fr: 'Texte du bouton en français',
    slide_btn_text_en: 'Texte du bouton en anglais',
    slide_btn_url: 'Lien du boutton',
    btn_text_fr: 'Texte en français',
    btn_text_en: 'Texte en anglais',
    front_image_label: "Image d'arrière plan",
    back_image_label: "Image d'avant plan",
    slide_label_is_external: 'Le lien est externe',
    slide_label_empty_image_error: 'Veuillez ajouter une image',
    delete_slide_modal_title: 'Supprimer un slide',
    delete_slide_modal_description: 'Voulez vous vraiment supprimer ce slide?',
    wrong_url: 'Lien incorrect',
    you_have_not_access_of_website: "Vous n'avez pas accès à cette partie",
    january_stats_label: 'Jan',
    february_stats_label: 'Fév',
    march_stats_label: 'Mar',
    april_stats_label: 'Avr',
    may_stats_label: 'Mai',
    june_stats_label: 'Juin',
    july_stats_label: 'Jui',
    august_stats_label: 'Août',
    september_stats_label: 'Sep',
    october_stats_label: 'Oct',
    november_stats_label: 'Nov',
    december_stats_label: 'Déc',
    homepage_welcome_statics_upgrade:
      'Les ventes sur la dernière semaine ont progressé de PERCENT%.',
    homepage_welcome_statics_downgrade:
      'Les ventes sur la dernière semaine ont baissé de PERCENT%.',
    image_optimised_lable: 'Image optimisée',
    label_disconnect: 'Se déconnecter',
    label_home: 'Accueil',
    label_profile: 'Profil',
    label_setting: 'Paramètre',
    page_menu_title: 'Ajouter des elements de menus',
    page_menu_sub_title: 'Structure du menu',
    page_menu_input_description: "Cliquez sur une page pour l'ajouter au menu",
    page_menu_input_label: 'Selectionner un menu',
    page_menu_drag_n_drop_label:
      "Glissez chaque element et placez le dans l'ordre que vous voulez",
    page_menu_drag_n_drop_order_label: 'Ordonner les menus',
    page_menu_add_product: "Cliquez sur un produit pour l'ajouter au menu",
    page_menu_add_page: "Cliquez sur une page pour l'ajouter au menu",
    page_menu_add_category: "Cliquez sur une catégorie pour l'ajouter au menu",
    open_in_new_tab: 'ouvrir dans un nouvel onglet',
    global_label_rows_per_page: 'Éléments par page',
    invalid_password:
      'Doit contenir 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.',
    menu_item_shop_home_categories: 'Categories de la boutique',
    page_category_drag_n_drop_label: 'Ordonner les categories',
    dialog_delete_color_title: 'Supprimer une couleur',
    dialog_delete_color_description:
      'Voulez vous vraiment supprimer cette couleur? Cette action est irreversible !',
    dialog_delete_size_title: 'Supprimer une taille',
    dialog_delete_size_description:
      'Voulez vous vraiment supprimer cette taille? Cette action est irreversible !',
    statistics_page_title: 'Statistiques',
    statistics_conversion_rate: 'Taux de conversion',
    statistics_customer_name: 'Nom',
    statistics_customer_expense: 'Dépense totale',
    statistics_customer_register_date: "Date d'inscription",
    select_image_with_size: 'Insérez une image supérieur à IMAGE_SIZE',
    other_settings_page_title: 'Autres paramètres',
    page_bottom_content_title: 'Contenu page',
    product_quantity_limit: 'Seuil de quantité de produit',
    product_quantity_threshold_title: 'Seuil',
    discount_coupon_amount_title: 'Montant des coupons de reduction',
    sponsorship_coupon_amount_title: 'Montant des coupons de parrainage',
    label_shipping_adresse: 'Adresse de livraison',
    label_billing_adresse: 'Adresse de facturation',
    VAT_included_if_applicable: 'TVA inclue si applicable',
    order_summary: 'Récapitulatif de la commande',
    sub_total: 'Sous-Total',
    discount: 'Coupon',
    shipping: 'Livraison',
    discount_code: 'Code coupon / cadeau',
    settings_updated_successfully: 'Paramètres mis a jour',
    select_user_label: 'Sélectionner un utilisateur',
    find_user_loading: "Recherche de l'utilisateur en cours",
    find_user_empty: 'Aucun utilisateur trouvé',
    select_status: 'Sélectionner un status',
    select_category: 'Sélectionner une catégorie',
    shopping_rate_cart_abandon: "Taux d'abandon du panier",
    retention_rate: 'Retention',
    average_command_value: 'Valeur de commande moyenne',
    refund_rates: 'Taux de remboursement',
    for_current_month: 'Pour ce mois',
    for_current_week: 'Pour cette semaine',
    for_current_year: 'Pour cette année',
    for_current_day: 'Pour aujourd’hui',
    best_customers_list: 'Meilleurs clients',
    select_tags: 'Sélectionner une étiquette',
    generate_bill: 'Générer la facture',
    download_bill: 'Télécharger la facture',
    bill_generation_has_latency:
      'La regeneration de la facture peux prendre un moment',
    total_unread_notification: 'Vous avez $total notifications non lues',
    menu_item_notifications: 'Notifications',
    menu_item_unread_notifications: 'Notifications non lues',
    menu_item_new_notifications: 'Nouvelles',
    menu_item_old_notifications: 'Précédentes',
    menu_item_notifications_show_all: 'Tout afficher',
    menu_item_notifications_delete_all: 'Tout supprimer',
    billing_informations: 'Informations de paiement',
    for_current_trimester: 'Pour ce trimestre',
    type_PARRAIN_CODE: 'Code de parrainage',
    mark_all_as_read: 'Tout marquer comme lu',
    mark_as_read: 'Marquer comme lu',
    notification_title: 'Titre',
    notification_status: 'Statut',
    notification_status_unread: 'Non lu',
    empty_data_notifications: 'Aucune notification',
    TVA: 'TVA',
    contact_us: 'Nous contacter',
    page_legal_terms_title: 'Mentions légales et politique de confidentialité',
    category_update_success: 'Catégorie mis a jour avec succès',
    category_create_success: 'Catégorie créée avec succès',
    menu_item_link: 'Liens Sociaux',
    add_link_modal_title: 'Ajouter un lien social',
    social_link_label: 'Lien social',
    icon_label: 'Choisir une icône',
    status: 'Est -il actif',
    dialog_delete_link_title: 'Supprimer un lien social',
    dialog_delete_link_description:
      'Voulez vous vraiment supprimer ce lien? Cette action est irreversible !',
    link_update_successfully: 'Lien social mis à jour avec succès',
    social_link_added_successfully: 'Lien social enregistré avec succès',
    // new
    internet_connexion_error:
      " Une erreur inconnue s'est produite. Veuillez ressayer plus tard",
    user_role_admin: 'Administrateur',
    user_role_edimaster: 'Édimestre',
    user_role_customer: 'Client',
    label_account_verified: 'Vérifié',
    label_account_not_verified: 'Non vérifié',
    label_account_activated: 'Actif',
    label_account_not_activated: 'Inactif',
    user_created_success: 'Utilisateur ajouté avec succès',
    user_updated_success: 'Utilisateur mis à jour avec succès',
    config_updated_success: 'Configuration mise à jour avec succès',
    table_head_label_verification: 'Vérification',
    page_quote_list: 'Demandes des devis',
    quote_table_head_created_date: 'Date de création',
    quote_table_head_company: 'Nom de la companie',
    quote_table_head_name: 'Nom',
    quote_table_head_email: 'Email',
    quote_table_head_phone: 'Numéro de téléphone',
    quote_table_head_status: 'Status',
    empty_quote_message: 'Aucun devis',
    delete_quote_dialog_title: 'Supprimer une demande de devis',
    dialog_confirm_delete_quote:
      'Souhaitez-vous vraiment supprimer cet demande de devis ?',
    quote_status_NOUVEAU: 'Nouveau',
    quote_status_CONCLUANT: 'Concluant',
    quote_status_NON_CONCLUANT: 'Non concluant',
    quote_status_ENVOYER: 'Envoyé',
    quote_status_ENCOURS_DE_TRAITEMENT: 'En cours de traitement',
    modal_change_quote_status_title: 'Changer le status de la demande de devis',
    quote_status_updated_success:
      'Status de la demande de devis mis à jour avec succès',
    quote_detail_modal_title: 'Détails de la demande de devis de {{company}}',
    global_label_close: 'Fermer',
    product_label_enabled: 'En vente',
    product_label_subtitle: 'Sous-titre',
    product_label_index: 'Index',
    product_label_index_error: "L'Index doit être superieur a zéro",
    product_label_start_price: 'Prix de départ',
    product_create_success_message: 'Produit ajouté avec succès',
    success_delete_product: 'Produit supprimé avec succès',
    product_field_professional_poser: 'Poseur professionnel',
    product_label_description: 'Description',
    product_label_type: 'Type',
    product_label_size: 'Taille',
    product_type_stikers: 'Sticker',
    product_type_removable: 'Amovible',
    add_size_success: 'Taille ajoutée avec succès',
    update_size_success: 'Taille mise à jour avec succès',
    size_field_title: 'Titre',
    size_field_subtitle: 'Sous titre',
    size_field_canvas_height: 'Hauteur',
    size_field_canvas_width: 'Largeur',
    size_field_canvas_radius: 'Rayon',
    size_installer_price: 'Prix de pose',
    size_field_price: 'Prix',
    size_field_need_help: 'Ajouter un aide pour le client',
    size_field_help_title: "Titre de l'aide",
    size_field_help: "Description de l'aide",
    size_field_need_vehicle_info:
      'Nécessite des informations sur le véhicule ?',
    size_field_pictogram: 'Ajouter le pictogramme',
    add_size_error_should_select_pictogram:
      'Vous devez sélectionner un pictogramme',
    add_size_modal_title: 'Ajouter une taille',
    update_size_modal_title: 'Mettre à jour une taille',
    delete_success_size: 'Taille supprimée avec succès',
    dialog_delete_support_title: 'Supprimer un support',
    dialog_delete_support_description:
      'Voulez vous vraiment supprimer ce support ? Cette action est irreversible !',
    add_support_dialog_title: 'Ajouter un support',
    update_support_dialog_title: 'Modifier un support',
    add_support_success: 'Support ajouté avec succès',
    update_support_success: 'Support mis à jour avec succès',
    delete_success_support: 'Support supprimé avec succès',
    support_label_can_have_background: 'Peut avoir un fond',
    support_field_extra_price: 'Prix en plus',
    support_field_title: 'Nom du support',
    product_label_supports: 'Supports',
    product_label_sizes: 'Tailles',
    product_label_sections: 'Coupes',
    update_section_modal_title: 'Modifier une coupe',
    add_section_modal_title: 'Ajouter une coupe',
    add_section_success: 'Coupe ajoutée avec succès',
    update_section_success: 'Coupe mise à jour avec succès',
    add_extra_sticker_modal_title: 'Ajouter un extra sticker',
    update_extra_sticker_modal_title: 'Modifier un extra sticker',
    add_extra_sticker_success: 'Extra Sticker ajouté avec succès',
    update_extra_sticker_success: 'Sticker mis à jour avec succès',
    delete_success_extra_sticker: 'Sticker supprimé avec succès',
    extra_sticker_need_installer_price: 'Prix de pose supplémentaire ?',
    extra_sticker_design_choice_possible: 'Choix du design possible ?',
    extra_sticker_field_installer_price: 'Prix de pose',
    product_label_extra_stickers: 'Extra Stickers',
    add_extra_support_modal_title: 'Ajouter un extra sticker',
    update_extra_support_modal_title: 'Modifier un extra sticker',
    product_field_graphic_service: 'Service graphique',
    product_field_verification: 'Vérification',
    success_update_product: 'Produit mis à jour avec succès',
    product_label_pose_video: 'Vidéo de pose',
    product_label_choose_pose_video: 'Choisir une vidéo de pose',
    navigation_item_faq: 'FAQ',
    add_faq_item_modal_title: 'Ajouter une question',
    general_question_faq: 'Questions générales sur le marquage du véhicule',
    about_adsquid_faq: 'Entreprise Adsquid',
    service_adsquid_faq: 'Services Adsquid',
    category_faq_title: 'Catégorie',
    levelOfPose: 'Niveau de pose',
    update_faq_item_modal_title: 'Modifier une question',
    delete_faq_success_message: 'Question supprimée avec succès',
    update_faq_success_message: 'Question mise à jour avec succès',
    add_faq_success_message: 'Question ajoutée avec succès',
    delete_faq_modal_title: 'Supprimer cet Faq',
    delete_faq_modal_description:
      'Voulez vraiment supprimer cet Faq ? Cette action est irreversible',
    faq_field_title: 'Question',
    faq_field_response: 'Réponse',
    navigation_item_font: 'Polices',
    add_font_item_modal_title: 'Ajouter une police',
    update_font_item_modal_title: 'Modifier une police',
    delete_font_success_message: 'Police supprimée avec succès',
    update_font_success_message: 'Police mise à jour avec succès',
    add_font_success_message: 'Police ajoutée avec succès',
    delete_font_modal_title: 'Supprimer cet Police',
    delete_font_modal_description:
      'Voulez vraiment supprimer cet Police ? Cette action est irreversible',
    font_field_title: 'Police',
    font_field_response: 'Description',
    product_label_templates: 'Gabarits',
    add_template_modal_title: 'Ajouter un gabarit',
    update_template_modal_title: 'Modifier un gabarit',
    add_template_success: 'Gabarit ajouté avec succès',
    update_template_success: 'Gabarit mis à jour avec succès',
    delete_success_template: 'Gabarit supprimé avec succès',
    template_field_title: 'Titre',
    template_field_file: 'Choisir un fichier',
    template_error_should_select_file: 'Vous devez choisir un fichier',
    template_label_copy_link: 'Copier le lien',
    template_delete_modal_title: 'Supprimer un gabarit',
    template_delete_modal_description:
      'Voulez vous vraiment supprimer ce gabarit ? Cette action est irreversible !',
    product_label_overview: 'Aperçu général',
    product_label_overview_title: 'Titre',
    product_overview_create_success: "Point d'aperçu créé avec succès",
    product_overview_delete_success: "Point d'aperçu supprimé avec succès",
    extra_sticker_price_depend_on_section:
      'Le prix en plus dépend de la coupe ?',
    extra_sticker_price_depend_on_size: 'Le prix en plus dépend de la taille ?',
    label_FORMAT_IMG: 'Images',
    label_FORMAT_VIDEO: 'Vidéos',
    label_FORMAT_EXCEL: 'Excel',
    label_FORMAT_WORD: 'Word',
    label_FORMAT_PDF: 'PDF',
    label_FORMAT_POWERPOINT: 'Powerpoint',
    label_FORMAT_PHOTOSHOP: 'Photoshop',
    label_FORMAT_ILLUSTRATOR: 'Illustrator',
    menu_item_image_settings: 'Paramètres des images',
    label_change_video: 'Changer la vidéo',
    field_overview: "Texte d'aperçu",
    other_settings_update_success: 'Paramètres mis à jour avec succès',
    label_graphic_service_cost: 'Coût du service graphique',
    label_installer_price: "Coût d'installation",
    label_shipping_express_cost: "Coût d'expédition (Express)",
    label_youtube_social_link: 'Lien youtube',
    label_facebook_social_link: 'Lien facebook',
    label_instagram_social_link: 'Lien instagram',
    label_shipping_standard_cost: "Coût d'expédition (Standard)",
    label_shipping_price: "Prix d'expédition",
    label_standard_cost: "Prix compris entre",
    label_standard_cost_max: "Valeur supérieure",
    label_standard_cost_min: "Valeur inférieure",
    label_synthetic_design_cost: 'Coût du design symétrique',
    label_verification_cost: 'Coût de la vérification',
    label_shipping_standard_days_number:
      "Nombre de jours d'expédition (Standard)",
    label_shipping_express_days_number:
      "Nombre de jours d'expédition (Express)",
    label_vat: 'TVA',
    label_start_shipping_express: "Temps minimal d'expédition (Express)",
    label_end_shipping_express: "Temps maximal d'expédition (Express)",
    product_label_gallery: 'Galerie',
    gallery_delete_modal_title: 'Supprimer une image',
    gallery_delete_modal_description:
      'Voulez vous vraiment supprimer cette image ? Cette action est irreversible !',
    gallery_delete_success: 'Image supprimée avec succès',
    gallery_add_success: 'Image ajoutée avec succès',
    add_gallery_image_modal_title: 'Ajouter une image',
    gallery_error_should_select_one:
      'Vous devez choisir au moins une taille ou une coupe ou un extra sticker ou un support',
    order_status_paid: 'Payé',
    order_status_draft: 'Brouillon',
    order_status_delivered: 'Livré',
    order_status_waiting: 'En attente',
    ordering_status_cancelled: 'Annulé',
    ordering_status_paid: 'Payé',
    ordering_status_deelivered: 'Livré',
    ordering_status_waiting: 'En attente de paiement',
    ordering_status_pending: 'En attente',
    ordering_status_order_in_printing: "En impression",
    ordering_status_order_in_verification: "En verification",
    ordering_status_waiting_for_bat: 'En attente du BAT',
    ordering_status_waiting_for_visuel: "En attente d'un visuel",
    ordering_quote: 'Devis',
    ordering_status_failed: 'Echoué',
    delete_order_modal_title: 'Supprimer une commande',
    delete_order_modal_description:
      'Voulez vous vraiment supprimer cette commande ? Cette action est irreversible !',
    order_modal_change_status_title: "Changer le status de la commande",
    order_delete_success: 'Commande supprimée avec succès',
    global_label_clear: 'Effacer',
    global_label_payment_method: 'Méthode de paiement',
    label_price_with_tva: 'Prix TTC',
    label_product_count: 'Nombre de produits',
    payment_method_BANK_TRANSFER: 'Virement bancaire',
    payment_method_PAYPAL: 'Paypal',
    payment_method_STRIPE: 'Carte de crédit',
    payment_method_NO_PAYMENT: 'Aucune',
    order_shipping_address: 'Adresse de livraison',
    order_billing_address: 'Adresse de facturation',
    label_billing: 'Informations sur le paiement',
    label_delivery: 'Informations sur la livraison',
    label_shipping: 'Informations sur la facturation',
    label_compagny: 'Entreprise',
    shipping_mode_STANDARD: 'Standard',
    shipping_mode_EXPRESS: 'Express',
    label_shipping_cost: "Coût d'expédition",
    global_label_price: 'Prix',
    global_label_subtotal: 'Sous-total',
    global_label_total: 'Total',
    label_custom_design: 'Design personnalisé',
    label_design_finished: 'Design terminé',
    label_synthetic_design: 'Design synthétique',
    label_extra_sticker: 'Extra sticker',
    label_section: 'Coupe',
    label_car_info: 'Information du véhicule',
    label_car_info_brand: 'Marque',
    label_car_info_color: 'Couleur',
    label_car_info_model: 'Modèle',
    label_car_info_year: 'Année',
    //new
    menu_item_coupons: 'Coupons',
    coupon_status_actived: 'Activé',
    coupon_status_draft: 'Brouillon',
    coupon_status_expired: 'Expiré',
    background_page_title: 'Liste de background',
    find_background_empty: 'Aucune background trouvé',
    find_image_empty: 'Aucun metier trouvé',
    find_picto_empty: 'Aucun pictogramme trouvé',
    background_page_add_new: 'Ajouter un background',
    picto_page_add_new: 'Ajouter un pictogramme',
    image_page_add_new: 'Ajouter un metier',
    picto_page_title: 'Liste de pictogramme',
    image_page_title: 'Liste de metier',
    picto_form_title: 'Titre',
    picto_form_description: 'Description',
    picto_form_image: 'Ajouter un metier',
    picto_create_success_message: 'Pictogramme ajouté avec succès',
    image_create_success_message: 'Metier ajoutée avec succès',
    picto_update_success_message: 'Pictogramme modifié avec succès',
    background_create_success_message: 'metier ajoutée avec succès',
    image_update_success_message: 'Metier modifié avec succès',
    background_update_success_message: 'Background modifiée avec succès',
    squid_studio_upload_logo_label: 'Choisir un logo',
    picto_error_logo: 'Vous devez choisir un logo',
    squid_error_json: 'Erreur ! le contenu que vous avez fournis est incorect ',
    dialog_delete_picto_title: 'Supprimer un pictograme',
    dialog_delete_shape_category_title: 'Supprimer la categorie {{name}}',
    dialog_delete_image_title: 'Supprimer un metier',
    dialog_delete_picto_description: 'Voulez vous vraiment supprimer ce pictograme ? Cette action est irreversible !',
    dialog_delete_category_shape_description: "Voulez-vous vraiment supprimer cette catégorie? ",
    dialog_delete_background_title: 'Supprimer une metier',
    dialog_delete_background_description:
      'Voulez vous vraiment supprimer ce background ? Cette action est irreversible !',
    dialog_delete_image_description:
      'Voulez vous vraiment supprimer ce metier ? Cette action est irreversible !',
    success_delete_picto: 'Pictogram supprimé avec succès',
    success_delete_background: 'Background supprimé avec succès',
    success_delete_image: 'Metier supprimé avec succès',
    job_page_title: 'Liste de métier',
    job_page_add_new: 'Nouveau métier',
    find_job_empty: 'Aucun métier trouvé',
    save_edit: 'Sauvegarder la modification',
    display_notice: 'Affichage',
    number_of_product: 'Nombre de produit',
    code_of_commande: 'Code de la commande',
    description: 'Description',
    notice_note: 'Notes',
    display_on_product: 'Afficher dans les produits',
    video_field_title: 'Lien de la video',
    video_field_error: 'Url invalide',
    squid_studio_gallery: 'Galerie',
    squid_studio_gallery_new: 'Ajouter une image',
    label_visuel: 'Visuel du design',
    label_reference: 'Réference paiement',
    download_visual_label: 'Télécharger le visuel sous le different format',
    menu_group_blog: 'Blogs',
    menu_item_article: 'Arcticles',
    blog_page_title: 'Liste des Articles',
    name_article: 'articles',
    edit_title: "Modifier l'article",
    add_title: 'Ajouter un article',
    article_add_new: 'Nouveau post',
    delete_article_modal_title: 'Supprimer un article',
    delete_article_modal_description:
      'Voulez vous vraiment supprimer cet article ? Cette action est irreversible !',
    article_delete_success: 'Article supprimé avec succès',
    success_update_article: 'Article mis à jour avec succès',
    article_label_description: 'Description',
    article_label_title: 'title',
    size_label_index: 'Index',
    size_label_index_error: "L'Index doit être superieur a zéro",
    product_label_slug: 'Slug',
    product_label_meta_title: 'Meta titre',
    product_label_meta_description: 'Meta Description',
    menu_item_categorie: 'Catégorie',
    addCategory: 'Ajouter une catégorie',
    page_categorie_list: "Catégorie d'articles",
    add_category_modal_title: 'Ajouter une catégorie',
    update_category_modal_title: 'Modifier la catégorie',
    update_category_msg: 'Modificaton effectuée',
    create_category_msg: 'Catégorie crée',
    delete_category_msg: 'Suppression effectuée',
    category_page_rows_per_page: 'Catégorie par page',
    billing_address_title: 'Adresse de Facturation',
    delivery_address_title: 'Adresse de Livraison',
    full_name: 'Nom et prénom',
    email: 'Email',
    phone: 'Téléphone',
    company: 'Entreprise',
    country: 'Pays',
    town: 'Ville',
    address: 'Address',
    postal_code: 'Code postal',
    discount_anout: 'Reduction',
    delivery: 'Expédiction',
    price_without_vta: 'Total hors TVA',
    sub_total_after_discount: 'Sous-total après reduction',
    delivery_mode: 'Mode de livraison',
    payment_method: 'Mode paiement',
    translate_key_shape: 'Formes',
    translate_key_template: 'Templates',
    translate_key_shape_title: 'Liste des formes',
    translate_key_shape_category_title: 'Liste catégorie  de formes',
    translate_key_add_new_shape: 'Ajouter une forme',
    translate_key_add_category_new_shape: 'Ajouter une categorie forme',
    translate_key_background_color_shape: 'Couleur de fond',
    translate_key_empty_shape_list: 'Aucune forme trouvé',
    translate_key_create_date: 'Créer le :',
    translate_key_modify_date: 'Modifier le :',
    "translate_key_template_list_title": "Liste des templates",
    "translate_key_template_title": "Templates",
    "translate_key_template_add_new": "Ajouter un nouveau template",
    "translate_key_template_edit": "Modifier le template {{name}}",
    "translate_key_content": "Contenu du template",
    "translate_key_image_previsualisation": "Image de prévisualisation",
    "translate_key_propriety": "Propriétés",
    "translate_key_propriety_description": "fonction additionnel et attributs",
    vat_number: "Numéro TVA",
    invalid_date_format: "Format de la date invalide",
    start_year_should_be_less_than_end_year: "L'annêe de pépart doit plus petit",
    "problem_ocurred": "Un problème inattendu est survenu ! essayer à noouveau plus tard",
    "added_with_success": "Ajout effectué avec succès",
    "updated_with_success": "Modification effectuée avec succès",
    "deleted_with_success": "Modification effectuée avec succès",
    "pictogram_required": "Vous n'avez pas ajouté de pictogram",
    "preview_image_required": "Vous n'avez pas ajouté une image de preview",
  },
};
