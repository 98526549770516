import React, { useEffect, useRef } from 'react';
import { jssPreset, StylesProvider } from '@mui/styles';
import { create } from 'jss';
import ReactGA from 'react-ga';
import CssBaseline from '@mui/material/CssBaseline';
import Routes from 'routes';
import 'index.css';

import 'translations';
import Auth from 'routes/Auth';
import { StyledEngineProvider } from '@mui/styled-engine-sc';
import { PaletteMode } from '@mui/material';
import SecureLS from 'secure-ls';

import NotistackProvider from 'components/NotistackProvider';
import { MotionLazyContainer } from 'components/animate';
import ScrollToTop from 'components/ScrollToTop';
import { ProgressBarStyle } from 'components/ProgressBar';
import { ChartStyle } from 'components/chart';
import ThemeProvider from './theme';
import { HelmetProvider } from 'react-helmet-async';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import config from 'config';
import { AuthProvider } from 'contexts/AuthContext';
import { setContext } from '@apollo/client/link/context';
import { NotificationProvider } from 'contexts/NotificationContext';
import { ArticleProvider } from 'contexts/ArticleContextProvider';

const ls = new SecureLS({ encodingType: 'aes' });

const jss = create({ plugins: [...jssPreset().plugins] });

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

const App = () => {
  const httpLink = createHttpLink({
    uri: config.api_url,
  });

  let initialMode = ls.get('palette_mode') || 'light';

  const notistackRef = useRef<any>();

  const [mode, setMode] = React.useState<PaletteMode>(initialMode);
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light';
          ls.set('palette_mode', newMode);
          return newMode;
        });
      },
    }),
    [],
  );

  const helmetContext = {};

  // React analytics
  useEffect(() => {
    ReactGA.initialize('');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  let accessToken: any = undefined;
  let user = undefined;
  try {
    accessToken = !!ls.get('token') ? ls.get('token') : undefined;
    user = !!ls.get('user') ? JSON.parse(ls.get('user')) : undefined;
  } catch (e) {}

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <StyledEngineProvider injectFirst>
      <HelmetProvider context={helmetContext}>
        <ColorModeContext.Provider value={colorMode}>
          <MotionLazyContainer>
            <NotistackProvider>
              <ThemeProvider>
                <StylesProvider jss={jss}>
                  <React.Fragment>
                    <CssBaseline />
                    <ProgressBarStyle />
                    <ChartStyle />
                    <ScrollToTop />
                    <ApolloProvider client={client}>
                      <AuthProvider authLogin={{ accessToken, user }}>
                        <ArticleProvider>
                          <NotificationProvider>
                            <Auth>
                              {/* //  Todo */}
                              {/*<ScrollReset />*/}
                              <Routes />
                              {/*{config.env === 'DEV' && <MockedButton/>}*/}
                            </Auth>
                          </NotificationProvider>
                        </ArticleProvider>
                      </AuthProvider>
                    </ApolloProvider>
                  </React.Fragment>
                </StylesProvider>
              </ThemeProvider>
            </NotistackProvider>
          </MotionLazyContainer>
        </ColorModeContext.Provider>
      </HelmetProvider>
    </StyledEngineProvider>
  );
};

export default App;
