import React, { ReactElement } from 'react';
import { makeStyles } from '@mui/styles';
import { Tooltip, Typography, Box, Theme } from '@mui/material';
import { ErrorHandler } from '../index';

const useStyles = makeStyles((theme: Theme) => ({
  arrow: {
    color: '#ffffff',
    // boxShadow: '0px 6px 6px rgba(0, 0, 0, 0.21)',
    fontSize: 10
  },
  tooltip: {
    backgroundColor: '#ffffff',
    borderRadius: 8,
    boxShadow:
      '0px 6px 6px rgba(0, 0, 0, 0.15), 0px -6px 6px rgba(0, 0, 0, 0.15)',
    padding: theme.spacing(2, 2),
    '& h5': {
      color: '#202124'
    }
  }
  // popper: {
  //     backgroundColor: 'transparent'
  // }
}));

interface CustomTooltipProps {
  children: ReactElement<any, any>;
  placement:
    | 'left'
    | 'right'
    | 'bottom'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'top-end'
    | 'top-start';
  title?: string;
  subtitle?: string;
}

export default function (props: CustomTooltipProps) {
  const classes = useStyles();

  return (
    <ErrorHandler>
      <Tooltip
        style={{
          zIndex: 10000000
        }}
        // interactive={true}
        classes={classes}
        title={
          <React.Fragment>
            {props.title && (
              <Typography variant={'h5'}>{props.title}</Typography>
            )}
            <Typography variant={'subtitle1'}>
              <Box component={'div'} color={'#A6ADB4'} mt={props.title && 2}>
                {props.subtitle}
              </Box>
            </Typography>
          </React.Fragment>
        }
        placement={props.placement}
        arrow={true}>
        {props.children}
      </Tooltip>
    </ErrorHandler>
  );
}
