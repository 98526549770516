// This is a custom component for the Quill editor

export default function handleSeeMore() {
    //   @ts-ignore
    const quill = this.quill;
    if (quill.getText().includes('---see more---')) {
        if (document.getElementById('see-more-btn')) {
            document.getElementById('see-more-btn')?.classList.add('active');
        }
    } else {
        quill.insertText(quill.getLength(), '');
        quill.insertText(quill.getLength(), "\n ---see more--- \n");
        document.getElementById('see-more-btn')?.classList.add('active');
        quill.setSelection(quill.getLength());
    }
}