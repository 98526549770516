import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { Box, Paper, Typography } from '@mui/material';
import { FormInput } from 'components/FormBuilder';
import * as Quill from 'quill';
import { styled } from "@mui/material/styles";
import EditorToolbar, { formats, redoChange, undoChange } from "../editor/EditorToolbar";
import handleSeeMore from "./seeMoreComponent";
import { UploadMediaTool } from "../index";
import { Storage } from "../../services/queries";

const RootStyle = styled(Box)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    // @ts-ignore
    border: `solid 1px ${theme.palette.grey[500_32]}`,
    '& .ql-container.ql-snow': {
        borderColor: 'transparent',
        ...theme.typography.body1,
        fontFamily: theme.typography.fontFamily,
    },
    '& .ql-editor': {
        minHeight: 200,
        maxHeight: 640,
        '&.ql-blank::before': {
            fontStyle: 'normal',
            color: theme.palette.text.disabled,
        },
        '& pre.ql-syntax': {
            ...theme.typography.body2,
            padding: theme.spacing(2),
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.grey[900],
        },
    },
}));

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}));

interface QuillEditorProps {
    className?: string;
    value: string;
    placeholder?: string;
    theme?: string;
    field: FormInput;
    error?: boolean;
    onBlur?: any;
    onChange: (content: string) => void;
    hiddenSeeMoreBtn?: boolean;
}


function QuillEditor(props: QuillEditorProps) {
    const {
        className,
        value,
        placeholder = "",
        error,
        onBlur,
        field: { show_paper = true, name, ...field },
        onChange,
        hiddenSeeMoreBtn = false,
        ...rest
    } = props;

    const [imageShow, setImageShow] = useState(false)
    const imageHandler = () => {
        setImageShow(true)
    }

    const [length, setLength] = useState<number | null>(null);
    const id = `field_${name}_id`;
    const modules = useMemo(() => ({
        toolbar: {
            container: `#${id}`,
            handlers: {
                undo: undoChange,
                redo: redoChange,
                "see-more": handleSeeMore,
                image: imageHandler
            },
        },
        syntax: true,
        clipboard: {
            matchVisual: false,
        },
        imageResize: {
            modules: ['Resize', 'DisplaySize', 'Toolbar']
        }
    }), []);
    const quillRef = useRef<ReactQuill>(null);
    const quill = quillRef.current?.getEditor()
    const [images, setImages] = useState<Storage[]>([])

    useEffect(() => {
        const range = quill?.getSelection()
        if (images.length !== 5 && range) {
            console.log("this is the url data", images[0].url)
            quill?.insertEmbed((range?.index ?? 0), "image", images[0].url, "user")
            setImageShow(false)
        }
    }, [images]);

    const input = () => (
        <div>
            <UploadMediaTool
                onlyOne
                onChange={({ urls }) => setImages(urls ?? [])}
                data={{
                    urls: images,
                    alignItems: "flex-start",
                }}
                editorMediaShow={imageShow}
            />
            <RootStyle
                sx={{
                    ...(error && {
                        //@ts-ignore
                        border: (theme) => `solid 1px ${theme.palette.error.main}`,
                    })
                }}
            >
                <EditorToolbar id={id} isSimple={true} hiddenSeeMoreBtn={hiddenSeeMoreBtn} />
                <ReactQuill
                    value={value}
                    modules={modules}
                    ref={quillRef}
                    formats={formats}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    onChange={(
                        content: string,
                        delta: any,
                        source: Quill.Sources,
                        editor: any
                    ) => {
                        if (field.maxChar) {
                            if (
                                !(editor.getLength() > field.maxChar) ||
                                editor.getLength() < (length ?? 0)
                            ) {
                                onChange(content);
                                setLength(editor.getLength());
                            } else {
                                onChange(value);
                            }
                        } else {
                            onChange(content);
                        }
                    }}

                />
            </RootStyle>
        </div>

    );

    return (
        <Box>
            <Box mb={1}>
                <LabelStyle>{field.label}</LabelStyle>
            </Box>
            {show_paper ? <Paper variant="outlined">{input()}</Paper> : input()}
            <Box
                component={'div'}
                mt={1}
                sx={{ color: field.maxChar && (length ?? 0) >= field.maxChar ? '#e53935' : undefined }}>
                {field.maxChar ? field.maxChar + ' caractères espaces compris' : ''}
            </Box>
        </Box>
    );
}

// @ts-ignore
export default QuillEditor;
