import { pxToRem, responsiveFontSizes } from '../utils/getFontValue';

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'Public Sans, sans-serif'; // Google Font
// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font

const typography = {
  fontFamily: FONT_PRIMARY,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    htmlFontSize: 300,
    textDecoration: 'none',
    h1: {
        fontWeight: 500,
        fontSize: '36px',
        lineHeight: '40px'
    },
    h2: {
        fontWeight: 700,
        lineHeight: 64 / 48,
        fontSize: pxToRem(32),
        ...responsiveFontSizes({sm: 32, md: 32, lg: 32}),
    },
    h3: {
        fontWeight: 400,
        lineHeight: '33px',
        fontSize: '24px',
    },
    h4: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(20),
        ...responsiveFontSizes({sm: 20, md: 24, lg: 24}),
    },
    h5: {
        fontWeight: 400,
        lineHeight: '26px',
        fontSize: pxToRem(16),
        ...responsiveFontSizes({sm: 18, md: 20, lg: 20}),
    },
    h6: {
        fontWeight: 400,
        lineHeight: '18px',
        fontSize: '13px',
    },
    subtitle1: {
        fontWeight: 500,
        lineHeight: 1.5,
        fontSize: pxToRem(20),
    },
    subtitle2: {
        fontWeight: 600,
        lineHeight: 22 / 14,
        fontSize: pxToRem(14),
    },
    body1: {
        fontWeight: 400,
        lineHeight: 1.5,
        fontSize: pxToRem(16),
    },
    body2: {
        lineHeight: 22 / 14,
        fontSize: pxToRem(14),
    },
    caption: {
        lineHeight: 1.5,
        fontSize: pxToRem(12),
    },
    overline: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(12),
        textTransform: 'uppercase',
    },
    button: {
        fontWeight: 400,
        lineHeight: '27px',
        fontSize: '20px',
        textTransform: 'initial',
    },
};

export default typography;
